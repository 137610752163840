<template>
    <div :class="classes">
        <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
                <v-heading v-if="$slots && $slots['title']" size="3">
                    <slot name="title" />
                </v-heading>

                <v-paragraph v-if="$slots && $slots['description']" size="sm" class="mt-1">
                    <slot name="description" />
                </v-paragraph>
            </div>
        </div>

        <div v-if="$slots && $slots['default']" class="mt-5 md:mt-0 md:col-span-2">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "md:grid md:grid-cols-3 md:gap-6",
        };
    },
    computed: {
        classes() {
            return `form-container ${this.baseClasses}`;
        },
    },
};
</script>
